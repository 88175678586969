import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import { Col, Container, Row } from "react-grid-system"
import HeroHeader from "../components/heroHeader"
import TextBlock from "../components/textBlock"
import HeroBlock from "../components/heroBlock"
import TabBlock from "../components/tabBlock"
import NavProducts from "../components/navProducts"
import TableBlock from "../components/tableBlock"
import CallToAction from "../components/callToAction"
import ImageBlockLegacy from "../components/imageBlockLegacy"
import { getProductPath } from "../components/pathUtils"
import { HelmetDatoCms } from "gatsby-source-datocms"

const ProductPage = ({ data: { page }, pageContext, location }) => {
  const lang = pageContext.locale
  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getProductPath(page, locale.locale),
    }
  })

  return (
    <Layout
      lang={lang}
      title={page.title}
      location={location}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} >
        <html lang={lang} />
      </HelmetDatoCms>
      <HeroHeader
        title={page.title}
        subtitle={page.subtitle}
        heroImage={page.heroImage}
        highlight={page.highlight}
      />
      <Wrapper>
        <Container>
          <Row>
            <ColNav lg={3}>
              <NavProducts lang={lang} />
            </ColNav>
            <Col lg={9}>
              <Content>
                {page.content.map((section, index) => (
                  <section key={section.id}>
                    {section.model.apiKey === "hero_block" && (
                      <HeroBlock
                        title={section.title}
                        text={section.text}
                        image={section.image}
                        gallery={section.gallery}
                        documents={section.documents}
                      />
                    )}
                    {section.model.apiKey === "text_block" && (
                      <TextBlock
                        title={section.title}
                        text={section.text}
                        highlight={section.highlight}
                      />
                    )}
                    {section.model.apiKey === "image_block" && (
                      <ImageBlockLegacy
                        title={section.title}
                        text={section.text}
                        image={section.image}
                        highlight={section.highlight}
                        alignment={section.rightAlignment}
                      />
                    )}
                    {section.model.apiKey === "tab_block" && (
                      <TabBlock tabs={section.details} />
                    )}
                    {section.model.apiKey === "table_block" && (
                      <TableBlock title={section.title} table={section.table} />
                    )}
                  </section>
                ))}
              </Content>
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <CallToAction
        title={page.callToAction.title}
        backgroundImage={page.callToAction.backgroundImage}
        slug={page.callToAction.slug}
        name={page.callToAction.name}
      />
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media screen and (max-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`

const Content = styled.div`
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ColNav = styled(Col)`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

export default ProductPage

export const query = graphql`
  query ProductPageQuery($slug: String!, $locale: String!) {
    page: datoCmsProductPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      ...AllProductSlugLocales
      title
      slug
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      id
      locale
      callToAction {
        title
        backgroundImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        slug
        name
      }
      content {
        ... on DatoCmsTableBlock {
          id
          title
          table
          model {
            apiKey
          }
        }
        ... on DatoCmsHeroBlock {
          ...HeroBlockDetails
        }
        ... on DatoCmsTextBlock {
          title
          text
          highlight
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsImageBlock {
          title
          text
          image {
            fluid(maxWidth: 520, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          highlight
          rightAlignment
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsTabBlock {
          id
          details {
            title
            content {
              ... on DatoCmsTableBlock {
                id
                title
                table
                model {
                  apiKey
                }
              }
              ... on DatoCmsTextBlock {
                title
                text
                highlight
                id
                model {
                  apiKey
                }
              }
              ... on DatoCmsHeroBlock {
                ...HeroBlockDetails
              }
            }
          }
          model {
            apiKey
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }

  fragment AllProductSlugLocales on DatoCmsProductPage {
    _allSlugLocales {
      locale
      value
    }
  }
`
